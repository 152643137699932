export default [
  {
    media: { icon: ['fas', 'mobile-alt'], color: 'warning', className: 'fs-4' },
    title: 'Mobile friendly',
    description:
      "Provide your team with a simple & easy to use mobile-friendly tool. Make it easy for them to do the right thing."
  },
  {
    media: { icon: ['fas', 'cog'], color: 'info', className: 'fs-5' },
    title: 'Powerful Configuration',
    description:
      'Setup trading restrictions in any market and control trading windows. Specify who is allowed to manage your restrictions.'
  },
  {
    media: { icon: ['fas', 'user-shield'], color: 'success', className: 'fs-6' },
    title: 'Security & Peace of Mind',
    description:
      'Your clients and deals are confidential. We get it. checkTrade uses enterprise grade authentication from Auth0 and a full audit log of all activity. '
  }
];
