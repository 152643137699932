// src/components/PrivateRoute.js

import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth0 } from "./react-auth0-spa";

import { authAppMetadataKey } from '../../config.js';
import CmtLoadingSpinner from '../common/CmtLoadingSpinner';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect, user } = useAuth0();
  const customerKey = authAppMetadataKey + 'customerId';

  useEffect(() => {
    //console.log('PrivateRoute loading is ' + loading + ' isAuthenticated is ' + isAuthenticated + ' path is ' + path);
    if (loading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname }
      });
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = props => {
    if (isAuthenticated) {
      if (user.email_verified) {
        if (user[customerKey]) {
          return <Component {...props} />;
        } else {
          if (props.location.pathname === '/signup') {
            return <Component {...props} />;
          } else {
            return <Redirect to='/signup' />;
          }
        }
      } else {
        if (props.location.pathname.startsWith('/verifyemail')) {
          return <Component {...props} />;
        } else {
          return <Redirect to={'/verifyemail?target=' + props.location.pathname} />;
        }
      }
    } else {
      return <CmtLoadingSpinner />;
    }
  }

  return (
    <React.Fragment>
      {user && <Route path={path} render={render} {...rest} />}
    </React.Fragment>
  );

};

export default PrivateRoute;
