import React, { useState, useEffect } from 'react';
import {
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Collapse,
  NavbarBrand,
  Container,
  NavLink,
  Button
} from 'reactstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import handleNavbarTransparency from '../../helpers/handleNavbarTransparency';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//import { useAuth0 } from "../auth0/react-auth0-spa";

const breakpoint = 'lg';

const NavbarStandard = props => {
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  // Auth0 authentication
  //const { isAuthenticated, loginWithRedirect, loading,
  //  logout, user, permissions } = useAuth0();

  //function signOut() {
  //  logout();
  //  props.history.replace('/');
  //}

  useEffect(() => {
    window.addEventListener('scroll', handleNavbarTransparency);
    return () => window.removeEventListener('scroll', handleNavbarTransparency);
  }, []);

  return (
    <Navbar
      dark
      fixed="top"
      expand={breakpoint}
      className={classNames('fs--1 font-weight-semi-bold navbar-standard navbar-theme', {
        'bg-dark': !navbarCollapsed
      })}
    >
      <Container>
        <NavbarBrand className="text-white" tag={Link} to="/">
          checkTrade
        </NavbarBrand>
        <NavbarToggler onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
        <Collapse isOpen={!navbarCollapsed} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink tag={Link} to="/features">
                Features
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/pricing">
                Pricing
              </NavLink>
            </NavItem>
            {/*
            <NavbarDropdown title={pageRoutes.name} items={pageRoutes.children} />
            <NavbarDropdown title={pluginRoutes.name} items={pluginRoutes.children} />
            <NavbarDropdown title={componentRoutes.name} items={componentRoutes.children} />
            <NavbarDropdown title={utilityRoutes.name} items={utilityRoutes.children} />
            */}
          </Nav>
          <Nav navbar className="ml-auto">
          {/*
            <NavItem>
              <NavLink tag={Link} to="/">
                <FontAwesomeIcon
                  icon="chart-pie"
                  id="dashboardTooltip"
                  className={`d-none d-${breakpoint}-inline-block`}
                />
                <UncontrolledTooltip placement="bottom" target="dashboardTooltip">
                  Dashboard
                </UncontrolledTooltip>
                <span className={`d-${breakpoint}-none`}>Dashboard</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/documentation">
                <FontAwesomeIcon
                  icon="book"
                  id="documentationTooltip"
                  className={`d-none d-${breakpoint}-inline-block`}
                />
                <UncontrolledTooltip placement="bottom" target="documentationTooltip">
                  Documentation
                </UncontrolledTooltip>
                <span className={`d-${breakpoint}-none`}>Documentation</span>
              </NavLink>
            </NavItem>
            */}
            <NavItem>
              <NavLink tag={Link} to="/check">
                <Button color="outline-light" size="sm" className="border-2x rounded-pill " type="button">
                  Login
                </Button>
              </NavLink>
            </NavItem>
            <NavItem>
            <NavLink tag={Link} to="/signup">
                <Button color="outline-light" size="sm" className="border-2x rounded-pill " type="button">
                  Get started for FREE
                  <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
                </Button>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarStandard;
