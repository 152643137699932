import React from 'react';
import processList from '../../data/feature/processList';
import Section from '../../components/common/Section';
import Process from './Process';
import SectionHeader from './SectionHeader';
import { isIterableArray } from '../../helpers/utils';

const Processes = () => (
  <Section>
    <SectionHeader
      title="Compliance made easy"
      subtitle="Built to access anywhere, checkTrade provides you a gorgeous design with enterprise-grade security & a streamlined UX so it's easy for your team to do the right thing."
    />
    {isIterableArray(processList) && processList.map((process, index) => <Process key={index} {...process} />)}
  </Section>
);

export default Processes;
