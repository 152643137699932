import google from '../../assets/img/logos/google.png';
import netflix from '../../assets/img/logos/netflix.png';
import amazon from '../../assets/img/logos/paypal.png';

export default [
  {
    description:
      "checkTrade is the best option for any large company who wants to protect their reputation and ensure they stay compliant with increasingly complex rules. Full traceability means we can answer any questions from our Board or Regulators.",
    author: 'Scott Zelinski',
    designation: 'Head of Compliance',
    companyImage: google,
    size: 45
  },
  {
    description:
      "We've become fanboys! It used to take hours for our compliance team to approve trades. Now it's done instantly! It's so easy to use and available whenever we need it.",
    author: 'Jeff Escandor',
    designation: 'Treasury Analyst',
    companyImage: netflix,
    size: 30
  },
  {
    description:
      "checkTrade saves us hours ever week. No more emails and manually checking our blacklists. Great design, easy to use, and enterprise-level security and support.",
    author: 'Liam Maartens',
    designation: 'Compliance Analyst',
    companyImage: amazon,
    size: 45
  }
];
