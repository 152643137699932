import React from 'react';
import { Spinner } from 'reactstrap';

const CmtLoadingSpinner = () => {

  return (
    <div className="text-center">
      <Spinner style={{ width: '3rem', height: '3rem' }} className="mt-3 mb-3" />
    </div>

  );
}

export default CmtLoadingSpinner;
