import React from 'react';
import Layout from './cmtLayouts/Layout';

import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';

import axios from 'axios';

import { Auth0Provider } from "./cmtComponents/auth0/react-auth0-spa";
import history from "./cmtComponents/auth0/history";

import { CommonStateProvider } from './cmtComponents/common/commonState';

const App = () => {

  // A function that routes the user to the right place
  // after login
  const onRedirectCallback = appState => {
    // Had a bug where react does not render after the push.
    // had to add a forceRefresh option to the history above
    // Suspect there is another root cause...
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };

  axios.defaults.baseURL = process.env.REACT_APP_API_URI;

  // Note use of useRefreshTokens and localstorage to
  // enable proper user experience for Safari and other
  // browsers strong cross-site tracking preventions.
  // See https://auth0.com/blog/securing-single-page-applications-with-refresh-token-rotation/?_ga=2.192350954.116408890.1589617536-771559925.1583489706
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN}
      client_id={process.env.REACT_APP_AUTH_CLIENT_ID}
      audience={process.env.REACT_APP_AUTH_API_ID}
      redirect_uri={window.location.origin}
      useRefreshTokens
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      <CommonStateProvider>
        <Layout />
      </CommonStateProvider>
    </Auth0Provider>
  );
};

export default App;
