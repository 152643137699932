//
// provides common state required throughout the app
// state in this provider
//
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from "../auth0/react-auth0-spa";
import axios from 'axios';
import { authAppMetadataKey } from '../../config.js';
import { getItemFromStore, setItemToStore, themeColors } from '../../helpers/utils';

import toggleStylesheet from '../../helpers/toggleStylesheet';

const CommonContext = React.createContext()

// Provider of common state elements
function CommonStateProvider({children}) {

  const [exchanges, setExchanges] =useState(getItemFromStore('exchangeList', ''));
  const [loadingExchanges, setLoadingExchanges] = useState(false);
  const [customerName, setCustomerName] = useState(false);
  const customerKey = authAppMetadataKey + 'customerId';
  const [parseUser, setParseUser] = useState({});

  // items from Falcon template (review when template is updated)
  const [isDark, setIsDark] = useState(getItemFromStore('isDark', false));
  const [isFluid, setIsFluid] = useState(getItemFromStore('isFluid', false));
  const [isRTL, setIsRTL] = useState(getItemFromStore('isRTL', false));
  const [isLoaded, setIsLoaded] = useState(false);
  const [currency, setCurrency] = useState('$');

  // Auth0 authentication
  var { isAuthenticated, user, getTokenSilently } = useAuth0();

  // values provided by this provider
  const value = {
    exchanges,
    loadingExchanges,
    customerName,
    parseUser,
    isFluid,
    setIsFluid,
    isRTL,
    setIsRTL,
    isDark,
    setIsDark,
    isLoaded,
    currency,
    setCurrency
  };

  const setStylesheetMode = mode => {
    setIsLoaded(false);
    setItemToStore(mode, value[mode]);
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));
  };

  useEffect(() => {
    setStylesheetMode('isFluid');
    // eslint-disable-next-line
  }, [isFluid]);

  useEffect(() => {
    setStylesheetMode('isRTL');
    // eslint-disable-next-line
  }, [isRTL]);

  useEffect(() => {
    setStylesheetMode('isDark');
    // eslint-disable-next-line
  }, [isDark]);

  // get exchanges for this customer and the parse user corresponding to this user when we are authenticated or the user details change
  useEffect(() => {
    // need to have an authenticated user who has joined a customer
    if (isAuthenticated && user && user[customerKey]) {
      //setFirstTime(false);
      // note cannot make useEffect async so need to define an async function
      // here and use it immediately
      const fetchData = async () => {
        setLoadingExchanges(true);
        try {
          // get customer data
          const token = await getTokenSilently();
          const response = await axios.get('/v1/customers/', {
              headers: { 'Authorization': `Bearer ${token}` }
          });
          const r = response.data.customers;
          // grab the customer name
          setCustomerName(r.name);
          // make a simple string list of the exchange codes
          // suitable for using as a paramater for the stocks API
          var exchangeList = '';
          if (r.exchanges.length > 0) {
            r.exchanges.forEach((item, i) => {
              exchangeList = exchangeList + item['value'] + ',';
            });
            // remove final comma
            exchangeList = exchangeList.substring(0,exchangeList.length-1);
          }
          setExchanges(exchangeList);
          setLoadingExchanges(false);

          // get the parse user
          const responseUser = await axios.get('/v1/users?email=' + user.email, {
              headers: { 'Authorization': `Bearer ${token}` }
          });
          setParseUser(responseUser.data.users[0]);
        }
        catch (error) {
          console.log('Error loading exchanges: ' + error.message);
          setLoadingExchanges(false);
        }
      };
      fetchData();
    }
  // eslint-disable-next-line
  },[isAuthenticated, user]);

  if (!isLoaded) {
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));

    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: isDark ? themeColors.dark : themeColors.light
        }}
      />
    );
  }

  return (
    <CommonContext.Provider value={value}>
        {children}
    </CommonContext.Provider>
  )
}

// custom hook to provide simpler experience
// usage example: const { customerName } = useCommon();
function useCommon() {
  const context = React.useContext(CommonContext)
  if (!context) {
    throw new Error(`useCommon must be used within a CommonProvider`)
  }

  const {
    exchanges,
    loadingExchanges,
    customerName,
    parseUser,
    isFluid,
    setIsFluid,
    isRTL,
    setIsRTL,
    isDark,
    setIsDark,
    isLoaded,
    currency,
    setCurrency
  } = context;

  return {
    exchanges,
    loadingExchanges,
    customerName,
    parseUser,
    isFluid,
    setIsFluid,
    isRTL,
    setIsRTL,
    isDark,
    setIsDark,
    isLoaded,
    currency,
    setCurrency
  }
}

CommonStateProvider.propTypes = { children: PropTypes.node };

// export the provider and the useCommon hook. Don't export the context
// - better user experience to use the custom hook
// ie they can do const { exchanges } = useCommon();
export { CommonStateProvider, useCommon }
