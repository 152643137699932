import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';

import Landing from '../cmtComponents/landing/Landing';
import ErrorLayout from './ErrorLayout';

// Private Route - secured with Auth0
import PrivateRoute from "../cmtComponents/auth0/PrivateRoute";

import loadable from '@loadable/component';
const MainAppLayout = loadable(() => import('./MainAppLayout'));
const WizardLayout = loadable(() => import('../cmtComponents/signup/wizard/WizardLayout'));
const JoinWizardLayout = loadable(() => import('../cmtComponents/signup/joinwizard/JoinWizardLayout'));
const AuthBasicLayout = loadable(() => import('../cmtLayouts/AuthBasicLayout'));

const Layout = () => {
  useEffect(() => {
    MainAppLayout.preload();
  }, []);

  // SJ: Handle special cases first otherwise then render the main app layout
  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route path="/errors" component={ErrorLayout} />
        <Route exact path="/signup/examplewizard" component={WizardLayout} />
        <Route exact path="/signup" component={JoinWizardLayout} />
        <Route path="/signup/:id" component={JoinWizardLayout} />
        <PrivateRoute path="/verifyemail" component={AuthBasicLayout} />
      <PrivateRoute component={MainAppLayout} />
      </Switch>
      <ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />
    </Router>
  );
};

export default Layout;
