// src/react-auth0-spa.js
import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";

// SJ ADDED - use simple jwt decode library to extract permissions from the JWT token
import jwt_decode from 'jwt-decode';

const DEFAULT_REDIRECT_CALLBACK = () => {
  //console.log('in DEFAULT_REDIRECT_CALLBACK redirecting to ' + window.location.pathname);
  window.history.replaceState({}, document.title, window.location.pathname);
}

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  // SJ Added
  const [permissions, setPermissions] = useState();

  useEffect(() => {
    const initAuth0 = async () => {

      try {
        const auth0FromHook = await createAuth0Client(initOptions);
        setAuth0(auth0FromHook);

        if (window.location.search.includes("code=") &&
            window.location.search.includes("state=")) {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        }

        const isAuthenticated = await auth0FromHook.isAuthenticated();

        setIsAuthenticated(isAuthenticated);

        if (isAuthenticated) {
          const user = await auth0FromHook.getUser();
          setUser(user);
          // SJ ADDED BELOW
          // Grab permissions from the access token and make
          // available to sub components to enable UI customisation
          // Note that access is still checked at the API (which is the
          // real subject of the access token) Supposedly bad practice to inspect
          // at the client, but at present this seems the most convenient
          // way to solve the problem of customising role-based UI behaviour
          //console.log('authenticated user ' + user.name);
          const token = await auth0FromHook.getTokenSilently();
          // store decoded permissions - used to determine navigation access
          try {
            const decoded = jwt_decode(token);
            setPermissions(decoded.permissions);
            //console.log('set Permissions ' + decoded.permissions);
          }
          catch (error){
            console.log('Got error decoding token: ' + JSON.stringify(token));
            throw(new Error('Error decoding token'));
          }
        }
      }
      catch (error) {
        console.log('Authorisation error: ' + JSON.stringify(error));
        throw(new Error('Authorisation error (' + error.message + ')'));
      }
      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  //
  // SJ: added refresh of user - to be done after calling getTokenSilently with
  // options to ignore the cache and update tokens
  // see UserProfileEdit
  //
  const refreshUser = async () => {
    const user = await auth0Client.getUser();
    setUser(user);
  }

  /*
  // SJ added items after logout
  */
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
        permissions,
        refreshUser
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
