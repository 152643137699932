import React from 'react';
import { Row, Col } from 'reactstrap';
import bg2 from '../../assets/img/generic/bg-1.jpg';
import Section from '../../components/common/Section';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

const Cta = () => (
  <Section overlay image={bg2} position="center top">
    <Row className="justify-content-center text-center">
      <Col lg={8}>
        <p className="fs-3 fs-sm-4 text-white">
          Join our community of team members buying and selling shares with confidence.
        </p>
        <Link className="btn btn-outline-light border-2x rounded-pill btn-lg mt-4 fs-0 py-2" to="/signup">
          Get started for FREE
          <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
        </Link>
      </Col>
    </Row>
  </Section>
);

export default Cta;
