import illustration1 from '../../assets/img/illustrations/1.svg';
import illustration2 from '../../assets/img/illustrations/2.svg';
import illustration3 from '../../assets/img/illustrations/3.svg';

export default [
  {
    icon: ['far', 'lightbulb'],
    iconText: 'PLAN',
    color: 'danger',
    title: 'Design your controls',
    description:
      "Setup trading restrictions to block securities and trading windows when it's safe for your team to trade. Configure controls on any global exchange and control how long approvals last.",
    image: illustration1
  },
  {
    icon: ['fas', 'check'],
    iconText: 'TRADE',
    color: 'info',
    title: 'Check trades anywhere',
    description:
      'With checkTrade as their guide, your team can buy and sell shares whenever it suits them, safe in the knowledge they are complying with all your trading rules.',
    image: illustration2,
    inverse: true
  },
  {
    icon: ['fas', 'search-dollar'],
    iconText: 'REVIEW',
    color: 'success',
    title: 'Full Transparency',
    description:
      'checkTrade records everything, so when you need to investigate or verify trading activity, everything you need is at your fingertips.',
    image: illustration3
  }
];
