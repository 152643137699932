export default [
  {
    title: 'Insider trading increases with market volatility',
    date: 'Apr 25',
    read: '8min'
  },
  {
    title: "May '20 New Features Newsletter",
    date: 'May 5',
    read: '3min',
    star: true
  },
  {
    title: 'Merry Christmas From the checkTrade Team.',
    date: 'Dec 25',
    read: '2min'
  },
  {
    title: 'The New checkTrade App',
    date: 'Jan 23',
    read: '10min'
  }
];
